import "core-js/es/array"
import "core-js/es/function"
import "core-js/es/map"
import "core-js/es/number"
import "core-js/es/object"
import "core-js/es/promise"
import "core-js/es/set"
import "core-js/es/string"
import "core-js/es/symbol"
import "core-js/es/typed-array/uint8-array.js"
import "core-js/es/weak-map"
import "core-js/es/weak-set"
import "core-js/modules/es.array.includes"
import "core-js/modules/es.symbol.async-iterator.js"
import ResizeObserver from "resize-observer-polyfill"
import "intersection-observer"

;(global as any).ResizeObserver = ResizeObserver

// missing forEach on NodeList for IE11
// @ts-ignore
if (window.NodeList && !NodeList.prototype.forEach) {
	// @ts-ignore
	NodeList.prototype.forEach = Array.prototype.forEach
}
